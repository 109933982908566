import "./styles.scss";
import { Grid, Skeleton, Typography } from "../../../Componentes/Material";

export const DetailSkeleton = () => {
  return (
    <Grid container gap={2}>
      <Grid item xs={12} className="image-container-skeleton">
        <Skeleton className="image-container-skeleton" height="100%" width="100vw" />
      </Grid>
      <Grid container gap={"20px"}>
        <Grid item xs={12}>
          <Grid container gap={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                <Skeleton width="150px" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                <Skeleton height={40} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container gap={1} direction="column">
            <Grid container gap={1} direction="column">
              <Skeleton width="150px" />
              <Skeleton height="3px" />
            </Grid>
            <Grid item xs={12} />
            <Skeleton width="100%" height="50px" />
            <Skeleton width="100%" height="50px" />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container gap={1} direction="column">
            <Grid container gap={1} direction="column">
              <Skeleton width="150px" />
              <Skeleton height="3px" />
            </Grid>
            <Grid item xs={12} />
            <Skeleton width="100%" height="50px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
