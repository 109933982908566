import React from "react";
import "./style.scss";
import { Backdrop, CircularProgress } from "../Material";

export const LoadingScreen = (props) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={props.show}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
