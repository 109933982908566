import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { PagSeguroBase } from "../PagSeguroBase";
import { CheckCircle } from "@mui/icons-material";
import { AKV } from "../../../Constants/Keys";
import { getMerchantInfo, getTemporaryToken } from "../../../Services/BlipFoodsApi";
import { payWithPSCard } from "../../../Services/BlipFoodsApi";
import { useSearchParams } from "react-router-dom";
import { setTrack } from "../../../Services/Tracking";
import { useUserStore } from "../../../Zustand/User";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Card = () => {
  const [CVCInfoOpen, setCVCInfoOpen] = React.useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [userName, setUserName] = useState();
  const [expiration, setExpiration] = useState();
  const [CVC, setCVC] = useState();
  const [CPF, setCPF] = useState();
  const [disabledButton, setDisabledButton] = useState(false);
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get("merchantId");
  const orderId = searchParams.get("orderId");
  const [user] = useUserStore((state) => [state.user]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTrack(
      "Pagamento pagseguro cartao exibicao",
      `Quantidade`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
  }, []);

  const payWithPagSeguro = () => {
    setTrack(
      "Pagamento pagseguro cartao selecao",
      `Fazer Pagamento`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    let year = expiration.split("/")[1];
    year = year.length === 2 ? `20${year}` : year;

    // TODO remover quando as validações passarem pra API
    const card = cardNumber.split(".").join("");
    const cpfNoSymbol = CPF.split(".").join("").replace("-", "");

    setDisabledButton(true);
    const encryptedCard = window.PagSeguro.encryptCard({
      publicKey: user.publicKey,
      holder: userName,
      number: card,
      expMonth: expiration.split("/")[0],
      expYear: year,
      securityCode: CVC,
    });

    getTemporaryToken(AKV.REACT_APP_BLIP_FOODS_TOKEN).then((res) => {
      getMerchantInfo(merchantId, res.data.temporaryToken).then((merchant) => {
        payWithPSCard(
          merchant.data.token,
          orderId,
          cpfNoSymbol,
          encryptedCard.encryptedCard,
          userName,
          CVC
        )
          .then(() => window.close())
          .catch((e) => {
            setTrack(
              "Pagamento pagseguro cartao erro",
              e.message,
              user.contactIdentity,
              user.botKey,
              user.adName
            );
            setDisabledButton(false);
            enqueueSnackbar("Confira os dados informados ou tente um cartão diferente.", {
              variant: "error",
            });
          });
      });
    });
  };

  const InfoModal = () => {
    return (
      <Dialog
        open={CVCInfoOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setCVCInfoOpen(false)}
      >
        <DialogTitle>Código de Segurança</DialogTitle>
        <DialogContent>
          <DialogContentText>
            É o código de 3 dígitos no verso do seu cartão.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseCVCInfo()}>Voltar</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCloseCVCInfo = () => {
    setTrack(
      "Pagamento pagseguro cartao cvc selecao",
      `voltar`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    setCVCInfoOpen(false);
  };

  const filterNumber = (value) => {
    return value.replace(/\D/g, "");
  };

  const handleDateChange = (dateValue) => {
    dateValue = dateValue.replace(/(^[\D]$|^[\D][\/\D]*[\D]$)/g, ""); // * só permite números e barra
    dateValue = dateValue.replace(/(\d{2})(\d{4})/g, "$1/$2"); // * Acrescenta barra divisória
    setExpiration(dateValue.substring(0, 7));
  };

  const handleCPFChange = (CPFValue) => {
    CPFValue = filterNumber(CPFValue);
    CPFValue = CPFValue.replace(/(\d{3})(\d)/, "$1.$2"); // * Adiciona um ponto após os 3 primeiros números
    CPFValue = CPFValue.replace(/(\d{3})(\d)/, "$1.$2");
    CPFValue = CPFValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // * Hífen nos dois últimos
    setCPF(CPFValue.substring(0, 14));
  };

  const handleCardChange = (cardNumber) => {
    cardNumber = filterNumber(cardNumber); // * Permite apenas dígitos
    cardNumber = cardNumber.replace(/(\d{4})/g, "$1."); // * Coloca um ponto a cada 4 caracteres
    cardNumber = cardNumber.replace(/\.$/, ""); // * Remove o ponto se estiver sobrando
    cardNumber = cardNumber.substring(0, 19); // * Limita o tamanho
    setCardNumber(cardNumber);
  };

  const handleOpenCVC = () => {
    setTrack(
      "Pagamento pagseguro cartao cvc exibicao",
      `Quantidade`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    setCVCInfoOpen(true);
  };

  const isFormValid = () => {
    // TODO validações de CPF
    const validCPF = CPF?.length === 14;
    // TODO validações de cartão
    const validCardNumber = cardNumber?.length === 19;
    return validCPF && validCardNumber && CPF && expiration && CVC && userName;
  };

  return (
    <PagSeguroBase>
      <InfoModal />
      <Typography variant="body2 bold">Cartão de crédito:</Typography>
      <Stack spacing={2} className="card-info-container">
        <FormControl variant="outlined">
          <InputLabel>Número do Cartão</InputLabel>
          <OutlinedInput
            onInput={(ev) => handleCardChange(ev?.target?.value)}
            value={cardNumber}
            label="Número do Cartão"
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  {cardNumber?.length === 19 ? <CheckCircle color="success" /> : <></>}
                </IconButton>
              </InputAdornment>
            }
          ></OutlinedInput>
        </FormControl>
        <TextField
          onInput={(ev) => setUserName(ev?.target?.value)}
          value={userName}
          label="Nome do Titular"
          inputProps={{ maxLength: 30 }}
        ></TextField>

        <Stack direction="row" spacing={1}>
          <TextField
            onInput={(ev) => handleDateChange(ev.target.value)}
            value={expiration}
            placeholder="mm/aaaa"
            inputProps={{ maxLength: 7 }}
            label="Validade"
          ></TextField>
          <FormControl variant="outlined">
            <InputLabel>CVC</InputLabel>
            <OutlinedInput
              inputProps={{ maxLength: 3 }}
              label="CVC"
              value={CVC}
              onInput={(ev) => setCVC(filterNumber(ev?.target?.value))}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => handleOpenCVC()}>
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              }
            ></OutlinedInput>
          </FormControl>
        </Stack>

        <TextField
          onInput={(ev) => handleCPFChange(ev?.target?.value)}
          value={CPF}
          inputProps={{ maxLength: 14 }}
          label="CPF do dono do cartão"
        ></TextField>
        <Button
          disabled={disabledButton || !isFormValid()}
          onClick={() => payWithPagSeguro()}
          variant="contained"
          color="secondary"
        >
          Fazer pagamento
        </Button>
      </Stack>
    </PagSeguroBase>
  );
};
