export const TRACKINGS = {
  backButton: "Catalogo externo botao voltar",
  merchantLogo: "Catalogo externo icone",
  cartButton: "Catalogo externo botao carrinho",
  showCatalog: "Catalogo externo abertura",
  showItem: "Catalogo externo item exibicao",
  addToCartShow: "Catalogo externo item exibicao",
  addToCartSelect: "Catalogo externo item selecao",
  actionButtonsShow: "Validacao fechar pedido catalogo externo exibicao",
  actionButtonsSelect: "Validacao fechar pedido catalogo externo selecao",
  scrollCatalog: "Catalogo externo scroll",
  cartSummary: "Carrinho atual catalogo externo resumo",
  cartProducts: "Carrinho atual catalogo externo produtos",
  cartComment: "Carrinho atual catalogo externo obs",
  cartSelect: "Carrinho catalogo externo selecao",
  cartShow: "Carrinho catalogo externo exibicao",
  goToHomePage: "Ir catalogo externo info",
  complementItem: "Catalogo externo item complemento",
  minimumOrderAmountNoticeDisplay: "Aviso de pedido minimo exibicao",
  minimumOrderAmountNoticeSelection: "Aviso de pedido minimo selecao",
};
