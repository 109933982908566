import React, { useEffect } from "react";
import { convertToReal, updatePrice } from "../../../Services/Common";
import { QuantitySelector } from "../../QuantitySelector";
import "./style.scss";
import { Box, Button, Divider, Grid, Typography } from "../../Material";
import { useDetailStore } from "../../../Zustand/ItemDetail";
import { ADD_1, GROUP_NOT_SELECTED, SUBSTRACT_1 } from "../../../Constants/Groups";

export const Extras = (props) => {
  const [product, setProduct, setProductAttribute] = useDetailStore((state) => [
    state.product,
    state.updateProduct,
    state.setProductAttribute,
  ]);

  useEffect(() => {
    const maxQuantity = product.maxQuantity === 0 ? 1000 : product.maxQuantity;
    setProductAttribute("maxQuantity", maxQuantity);
  }, []);

  const editItemQuantity = (
    isBeingAdded,
    itemPrice,
    itemIndex,
    maxQuantity,
    actualQuantity
  ) => {
    let newQuantity = isBeingAdded ? ADD_1 : SUBSTRACT_1;
    newQuantity = newQuantity + actualQuantity;

    const updatedProduct = product;
    itemPrice = updatePrice(itemPrice, isBeingAdded, product.totalValue);
    updatedProduct.totalValue = itemPrice;
    const currentQuantity =
      product.groupViewDto[props.groupIndex].groupItemViewDto[itemIndex].quantity;

    const updatedQuantity = changeQuantitySelected(
      newQuantity,
      maxQuantity,
      currentQuantity
    );
    updatedProduct.groupViewDto[props.groupIndex].groupItemViewDto[itemIndex].quantity =
      updatedQuantity;
    updatedProduct.groupViewDto[props.groupIndex].groupItemViewDto[itemIndex].isSelected =
      !(newQuantity === GROUP_NOT_SELECTED);
    setProduct(updatedProduct);
  };

  const changeQuantitySelected = (newQuantity, maxQuantity, actualQuantity) => {
    if (newQuantity > maxQuantity && maxQuantity !== 0) {
      return actualQuantity;
    } else {
      return newQuantity;
    }
  };

  const handleSelectEvent = (itemIndex, price) => {
    const updatedProduct = product;
    price = updatePrice(price, true, product.totalValue);
    updatedProduct.totalValue = price;
    updatedProduct.groupViewDto[props.groupIndex].groupItemViewDto[
      itemIndex
    ].isSelected = true;
    updatedProduct.groupViewDto[props.groupIndex].groupItemViewDto[
      itemIndex
    ].quantity = 1;
    setProduct(updatedProduct);
  };

  const checkIfReachedMaxQnt = (itemQuantity, itemMaxQuantity) => {
    if (props.maxQuantity !== 0) {
      const selectedItems = product.groupViewDto[
        props.groupIndex
      ].groupItemViewDto.filter((item) => item.isSelected);

      let selectedQnt = 0;
      selectedItems.forEach((item) => {
        selectedQnt = selectedQnt + item.quantity;
      });
      if (itemMaxQuantity === 0) {
        return selectedQnt >= props.maxQuantity;
      } else {
        // * se a quantidade selecionada for maior que a maxima (do item)
        // * ou se os itens selecionados (somados) chegaram no limite do grupo
        return itemQuantity >= itemMaxQuantity || selectedQnt >= props.maxQuantity;
      }
    } else {
      // * caso a qnt max do item seja 0 ele é ilimitado.
      if (itemMaxQuantity !== 0) {
        return itemQuantity >= itemMaxQuantity;
      }
      return false;
    }
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <Grid item xs={12}>
        <div className="side-by-side-container">
          <Typography variant="body2" color={props.error ? "errorColor" : "primary"}>
            <strong>{props.title}</strong>
          </Typography>
          <Typography
            variant="caption"
            color={props.error ? "errorColor" : "colorContentDisabled"}
          >
            {props.subtitle}
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} paddingTop="4px !important">
        <Divider color={props.error ? "errorColor" : "#1976D2"} />
      </Grid>

      <Grid item xs={12} paddingTop="0px !important">
        {props?.items?.map((item, index) => {
          return (
            <Box component="div" marginTop={"10px"} key={index}>
              <div className="side-by-side-container">
                <div>
                  <Typography variant="body2" className="extras-item-name">
                    {item.name}
                  </Typography>

                  <Typography variant="caption" className="extras-item-description">
                    {item.description}
                  </Typography>
                  {item.price > 0 ? (
                    <Typography variant="caption" color="primary">
                      <strong>+ {convertToReal(item.price)}</strong>
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  {item.quantity < 1 ? (
                    <Button
                      disabled={checkIfReachedMaxQnt(index)}
                      variant="contained"
                      size="small"
                      onClick={() => handleSelectEvent(index, item.price)}
                    >
                      Adicionar
                    </Button>
                  ) : (
                    <QuantitySelector
                      increaseEvent={() =>
                        editItemQuantity(
                          true,
                          item?.price,
                          index,
                          item?.maxQuantity,
                          item?.quantity
                        )
                      }
                      decreaseEvent={() =>
                        editItemQuantity(
                          false,
                          item?.price,
                          index,
                          item?.maxQuantity,
                          item?.quantity
                        )
                      }
                      quantity={item?.quantity}
                      minQuantity={item?.minQuantity}
                      maxQuantity={item?.maxQuantity}
                      removable={true}
                      disabled={checkIfReachedMaxQnt(item?.quantity, item?.maxQuantity)}
                    />
                  )}
                </div>
              </div>
              <Box component="div" marginTop={"10px"}>
                <Divider />
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};
