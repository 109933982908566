import React, { useContext } from "react";
import "./style.scss";
import { convertToReal } from "../../Services/Common";
import { useUserStore } from "../../Zustand/User";
import { Box } from "../../Componentes/Material";

export const HomeMessageMinimumPrice = () => {
  const [user] = useUserStore((state) => [state.user]);
  return (
    <Box sx={{ backgroundColor: "surfaceColor1" }}>
      {user.minimumPriceValue <= 0 ? (
        <></>
      ) : (
        <div className="message-minimum-price">
          <div className="text-minimum-price">
            Pedido mínimo {convertToReal(user.minimumPriceValue)}
          </div>
        </div>
      )}
    </Box>
  );
};
