import React, { useState, useEffect } from "react";
import "./style.scss";
import { PagSeguroBase } from "../PagSeguroBase";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getMerchantInfo,
  getTemporaryToken,
  getOrder,
  payWithCodePix,
} from "../../../Services/BlipFoodsApi";
import { AKV } from "../../../Constants/Keys";
import { setTrack } from "../../../Services/Tracking";
import { useUserStore } from "../../../Zustand/User";
import { Button, Stack, TextField, Typography } from "../../../Componentes/Material";
import { useSnackbar } from "notistack";

export const Pix = () => {
  const [codePix, setCodePix] = useState("");
  const navigate = useNavigate();
  const [timer, setTimer] = useState("05:00");
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get("merchantId");
  const orderId = searchParams.get("orderId");
  const userCpf = searchParams.get("cpf");
  const contactIdentity = searchParams.get("contactIdentity");
  const [user] = useUserStore((state) => [state.user]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const merchantId = searchParams.get("merchantId");
    const orderId = searchParams.get("orderId");
    startTimer(300); //60 segundos * 5
    getTemporaryToken(AKV.REACT_APP_BLIP_FOODS_TOKEN).then((res) => {
      getMerchantInfo(merchantId, res.data.temporaryToken).then((merchant) => {
        merchant = merchant.data;
        payWithCodePix(merchant.token, orderId, userCpf).then((pix) => {
          setCodePix(pix.data.codePix);
        });
      });

      setTrack(
        "Pagamento pagseguro pix exibicao",
        `Quantidade`,
        user.contactIdentity,
        user.botKey,
        user.adName
      );
    });
  }, []);

  const startTimer = (duration) => {
    var timer = duration,
      minutes,
      seconds;
    const intervalTime = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setTimer(minutes + ":" + seconds);

      if (--timer < 0) {
        clearInterval(intervalTime);
        navigate(
          `/payment/expiredPix?merchantId=${merchantId}&orderId=${orderId}&cpf=${userCpf}&contactIdentity=${contactIdentity}`
        );
      }
    }, 1000);
  };

  const copyTextToClipboard = (text) => {
    window.navigator.clipboard.writeText(text);
    setTrack(
      "Pagamento pagseguro pix selecao",
      `Copiar código`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    enqueueSnackbar("Código copiado com sucesso.", { variant: "success" });
  };

  return (
    <PagSeguroBase>
      {codePix ? (
        <Stack spacing={4} className="pix-info-container">
          <Typography alignSelf="center">Pix:</Typography>
          <Typography textAlign="center">
            Copie o código abaixo e use o Pix Copia e Cola no aplicativo do seu banco:
          </Typography>
          <TextField
            defaultValue={codePix}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ContentCopyOutlinedIcon />}
            className="card-continue-btn"
            onClick={() => copyTextToClipboard(codePix)}
          >
            Copiar
          </Button>
          <Typography textAlign="center">
            Você tem {timer} minutos para fazer o pagamento.{" "}
          </Typography>
        </Stack>
      ) : (
        <></>
      )}
    </PagSeguroBase>
  );
};
