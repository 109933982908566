import axios from "axios";

export const setTrack = (eventName, value, contactIdentity, botKey, adName) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: botKey,
  };

  const body = {
    id: "{{$guid}}",
    to: "postmaster@analytics.msging.net",
    method: "set",
    type: "application/vnd.iris.eventTrack+json",
    uri: "/event-track",
    resource: {
      category: eventName,
      action: value,
      contact: {
        identity: contactIdentity,
      },
      extras: { TemplateNameSkill: adName ? adName : "" },
    },
  };

  return axios.post("https://msging.net/commands", body, { headers: headers });
};
