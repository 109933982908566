import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Detail } from "./Pages/Detail";
import { Menu } from "./Pages/Menu";
import { Cart } from "./Pages/Cart";
import { Layout } from "./Componentes/Layout";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { PagSeguroBase } from "./Pages/PagSeguro/PagSeguroBase";
import { Pix } from "./Pages/PagSeguro/Pix";
import { Card } from "./Pages/PagSeguro/Card";
import { ExpiredPix } from "./Pages/PagSeguro/ExpiredPix";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createTheme } from "./Componentes/Material";
import {
  COLOR_CONTENT_DISABLE,
  COLOR_PRIMARY,
  ERROR,
  SURFACE_COLOR_1,
  SURFACE_COLOR_2,
  SURFACE_COLOR_4,
} from "./_tokens";
import { PagSeguro } from "./Pages/PagSeguro";

export default function App() {
  const MuiTheme = createTheme({
    typography: {
      h5: {
        color: COLOR_PRIMARY,
      },
    },
    palette: {
      secondary: {
        main: SURFACE_COLOR_4,
      },
      primary: {
        main: COLOR_PRIMARY,
      },
      surfaceColor1: SURFACE_COLOR_1,
      surfaceColor2: SURFACE_COLOR_2,
      colorContentDisabled: COLOR_CONTENT_DISABLE,
      errorColor: ERROR,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            height: "40px",
            borderRadius: "8px",
            textTransform: "none !important",
          },
        },
      },
    },
  });

  return (
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      dense={true}
      autoHideDuration={4000}
    >
      <BrowserRouter>
        <ThemeProvider theme={MuiTheme}>
          <Layout>
            <Routes>
              <Route path="/" element={<Menu />} />
              <Route path="/detail/:productId" element={<Detail />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/payment" element={<PagSeguro />} />
              <Route path="/payment/card" element={<Card />} />
              <Route path="/payment/pix" element={<Pix />} />
              <Route path="/payment/expiredPix" element={<ExpiredPix />} />
              <Route path="*" element={<Menu />} />
            </Routes>
          </Layout>
        </ThemeProvider>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals();
