import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { setTrack } from "../../Services/Tracking";
import { TRACKINGS } from "../../Constants/Trackings";
import { getUrlParams } from "../../Services/Common";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  ShoppingCartIcon,
  Toolbar,
  Typography,
} from "../Material";
import { useCartStore } from "../../Zustand/Cart";
import "./../../_tokens.scss";
import { ArrowBack } from "@mui/icons-material";
import { useUserStore } from "../../Zustand/User";

export const Navbar = () => {
  const [cart] = useCartStore((state) => [state.cart]);
  const [user] = useUserStore((state) => [state.user]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (trackEvent, route) => {
    let screenName = location.pathname === "/" ? "home" : location.pathname;
    screenName = screenName.replace(/[^A-Za-z]+/g, "");

    setTrack(trackEvent, screenName, user.contactIdentity, user.botKey, user.adName);
    if (route === "back") {
      navigate(-1);
    } else {
      navigate(
        `${route}${getUrlParams(user.merchantId, user.contactIdentity, user.adName)}`
      );
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color="secondary">
        <Toolbar>
          {location.pathname !== "/" ? (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClick(TRACKINGS.merchantLogo, "back")}
            >
              <ArrowBack />
            </IconButton>
          ) : (
            ""
          )}
          <Avatar
            edge="start"
            sx={{ mr: 2 }}
            alt="Logotipo do merchant"
            src={user?.image}
            onClick={() => handleClick(TRACKINGS.merchantLogo, "")}
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
            onClick={() => handleClick(TRACKINGS.merchantLogo, "")}
          >
            {user?.name ? user?.name : "Carregando ..."}
          </Typography>
          <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={() => handleClick(TRACKINGS.cartButton, "/cart")}
          >
            <Badge badgeContent={cart?.products?.length} color="error">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
