import React, { useState } from "react";
import "./style.scss";
import { convertToReal, updatePrice } from "../../../Services/Common";
import { Divider, Grid, Radio, Typography } from "../../Material";
import { useDetailStore } from "../../../Zustand/ItemDetail";
import { SELECTED_INITIAL_VALUE } from "../../../Constants/Groups";

export const OneChoice = (props) => {
  const [selected, setSelected] = useState(SELECTED_INITIAL_VALUE);
  const [product, setProduct] = useDetailStore((state) => [
    state.product,
    state.updateProduct,
  ]);

  function updateItemDetail(itemIndex, groupListIndex, isBeingAdded, price) {
    const updatedProduct = product;
    updatedProduct.groupViewDto[groupListIndex].groupItemViewDto[itemIndex].isSelected =
      isBeingAdded;
    price = updatePrice(price, isBeingAdded, product.totalValue);
    updatedProduct.totalValue = price;
    setProduct(updatedProduct);
  }

  function handleClickEvent(itemIndex, price) {
    if (selected === SELECTED_INITIAL_VALUE) {
      setSelected(itemIndex);
      updateItemDetail(itemIndex, props.groupListIndex, true, price);
    } else if (itemIndex !== selected) {
      updateItemDetail(selected, props.groupListIndex, false, price);
      setSelected(itemIndex);
      updateItemDetail(itemIndex, props.groupListIndex, true, price);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          className="side-by-side-container"
          style={{ justifyContent: "space-between" }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold" }}
            color={props.error ? "errorColor" : "primary"}
          >
            {props.nameGroup}
          </Typography>
          <Typography
            variant="caption"
            color={props.error ? "errorColor" : "colorContentDisabled"}
          >
            Obrigatório
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} paddingTop="4px !important">
        <Divider color={props.error ? "errorColor" : "primary"} />
      </Grid>
      <Grid item xs={12} paddingTop="0px !important">
        {props.groupItems.map((item, index) => {
          return (
            <div key={index} onClick={() => handleClickEvent(index, item.price)}>
              <div className="side-by-side-container">
                <div className="product-info-oneChoice">
                  <Typography variant="body2" mt="10px">
                    {item?.name}
                  </Typography>
                  <Typography variant="caption" color="colorContentDisabled">
                    {item?.description}
                  </Typography>
                  {item.price > 0 ? (
                    <Typography variant="caption" color="primary">
                      <strong>+ {convertToReal(item.price)}</strong>
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>
                <Radio checked={item?.isSelected} value={index} name="OneChoice" />
              </div>
              <Grid item xs={12} mt="10px">
                <Divider />
              </Grid>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};
