import React, { useContext, useRef, useState } from "react";
import { CartItem } from "../../Componentes/CartItem";
import "./style.scss";
import { convertToReal } from "../../Services/Common";
import { sendCart } from "../../Services/BlipFoodsApi";
import { TRACKINGS } from "../../Constants/Trackings";
import { setTrack } from "../../Services/Tracking";
import { LoadingScreen } from "../../Componentes/LoadingScreen";
import { EmptyContent } from "../../Componentes/EmptyContent";
import { TextArea } from "../../Componentes/TextArea";
import { ConfirmDialogPriceMinimum } from "../../Componentes/ConfirmDialogPriceMinimum";
import { useNavigate } from "react-router-dom";
import { getUrlParams } from "../../Services/Common";
import { Alert, Button, Grid, Typography } from "../../Componentes/Material";
import { useCartStore } from "../../Zustand/Cart";
import { useUserStore } from "../../Zustand/User";
import { WHATSAPP_API } from "../../Constants/Urls";
import { COMMENT_ATTRIBUTE } from "../../Constants/zustand";

export const Cart = () => {
  const [user] = useUserStore((state) => [state.user]);
  const [cart, setCartAttribute] = useCartStore((state) => [
    state.cart,
    state.setCartAttribute,
  ]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isGoingToWhatsapp, setIsGoingToWhatsapp] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const redirectRef = useRef();

  const navigate = useNavigate();

  const textDialog = `O valor do pedido mínimo para esta loja é de ${convertToReal(
    user.minimumPriceValue
  )}`;

  useState(() => {
    window.scrollTo(0, document.body.scrollHeight);
    const cartState = cart.products.length > 0 ? "carrinho cheio" : "carrinho vazio";
    setTrack(
      TRACKINGS.cartShow,
      `Quantidade | ${cartState}`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
  }, []);

  const getSelectGroupItems = (groupList) => {
    let groupObj = [];
    groupList.forEach((group) => {
      let selectedItems = group.groupItemViewDto.filter(
        (item) => item.isSelected === true
      );
      if (selectedItems.length > 0) {
        selectedItems = selectedItems.map((item) => {
          return {
            id: item.id,
            quantity: group.groupType === "Extras" ? item.quantity : 1,
          };
        });
        groupObj.push({ id: group.id, cartGroupItemDto: selectedItems });
      }
    });
    return groupObj;
  };

  const validMinimumPrice = () => {
    if (cart.totalValue < user.minimumPriceValue) {
      setOpenDialog(true);
      setTrack(
        TRACKINGS.minimumOrderAmountNoticeDisplay,
        "Quantidade",
        user.contactIdentity,
        user.botKey,
        user.adName
      );
    } else {
      sendCartToBlip();
    }
  };

  const sendCartToBlip = () => {
    setShowSpinner(true);

    const { allProducts, productQuantity } = getProductInfoTracking();

    setTrack(
      TRACKINGS.cartSelect,
      "Avancar para whatsapp",
      user.contactIdentity,
      user.botKey,
      user.adName
    );

    setTrack(
      TRACKINGS.cartSummary,
      `${productQuantity} | ${convertToReal(cart.totalValue)} | ${convertToReal(
        cart.comment
      )}`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );

    setTrack(
      TRACKINGS.cartProducts,
      allProducts,
      user.contactIdentity,
      user.botKey,
      user.adName
    );

    const items = cart.products.map((item) => {
      return {
        id: item.id,
        quantity: item.quantity,
        comment: item.comment,
        cartGroupDto: getSelectGroupItems(item.groupViewDto),
      };
    });

    sendCart(user.contactIdentity, user.merchantId, cart.comment, items, user.token)
      .then(() => {
        setShowSpinner(false);

        setIsGoingToWhatsapp(true);

        window.close();
        redirectRef.current.click();
      })
      .catch(() => setShowSpinner(false));
  };

  const getProductInfoTracking = () => {
    let allProducts = "";
    let productQuantity = 0;
    cart.products.forEach((item) => {
      let itemValue = item.salePrice ? item.salePrice : item.value;
      itemValue = convertToReal(itemValue);
      allProducts += `${item.name} | ${itemValue} | ${item.quantity} | `;
      productQuantity += item.quantity;
    });
    return { allProducts, productQuantity };
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDisagreeEvent = () => {
    handleDialogClose();
    setTrack(
      TRACKINGS.minimumOrderAmountNoticeSelection,
      "Voltar",
      user.contactIdentity,
      user.botKey,
      user.adName
    );
  };

  const handleAgreeEvent = () => {
    handleDialogClose();
    setTrack(
      TRACKINGS.minimumOrderAmountNoticeSelection,
      "Escolher mais itens",
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    navigate(getUrlParams(user.merchantId, user.contactIdentity, user.adName));
  };

  return (
    <div className="cart-container">
      <ConfirmDialogPriceMinimum
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        handleAgreeEvent={handleAgreeEvent}
        handleDisagreeEvent={handleDisagreeEvent}
        title="Pedido Mínimo"
        text={textDialog}
      />
      <button
        className="redirect-button"
        ref={redirectRef}
        onClick={() => window.open(`${WHATSAPP_API}${user.phone}`, "_self")}
      />
      <LoadingScreen show={showSpinner} />
      {cart?.products?.length > 0 ? (
        <Grid
          container
          gap={2}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
            >
              Carrinho
            </Typography>
          </Grid>
          {cart?.products.map((item, index) => {
            return (
              <Grid item xs={12} key={index}>
                <CartItem
                  id={item.id}
                  key={item.id}
                  imageUri={item.imageUrl}
                  name={item.name}
                  description={item.description}
                  value={item.totalValue}
                  peopleServided={item.peopleToServe}
                  quantity={item.quantity}
                  itemIndex={index}
                  extras={item.extras}
                ></CartItem>
              </Grid>
            );
          })}
          <Grid item xs>
            <div className="side-by-side-container">
              <Typography variant="overline" display="block" gutterBottom>
                SUBTOTAL
              </Typography>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                gutterBottom
                color="primary"
              >
                {convertToReal(cart.totalValue)}
              </Typography>
            </div>
          </Grid>
          {cart.totalValue < user.minimumPriceValue ? (
            <Grid item xs={12}>
              <Alert severity="warning">
                O valor do pedido mínimo para esta loja é de
                {convertToReal(user.minimumPriceValue)}
              </Alert>
            </Grid>
          ) : (
            <></>
          )}

          {cart.totalValue > user.minimumPriceValue ? (
            <Grid item xs={12}>
              <Alert severity="info">
                Entrega e pagamento serão escolhidos no Whatsapp
              </Alert>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12}>
            <TextArea
              maxRows={3}
              label="Observações (opcional)"
              value={cart?.comment}
              maxLength={255}
              emitChange={(value) => setCartAttribute(COMMENT_ATTRIBUTE, value)}
            />
          </Grid>
          <Grid item xs>
            <div className="center-item-container">
              <Button
                variant="contained"
                onClick={validMinimumPrice}
                disabled={isGoingToWhatsapp}
              >
                Fazer pedido
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : (
        <EmptyContent />
      )}
    </div>
  );
};
