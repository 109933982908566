import React, { useContext, useEffect, useState } from "react";
import { getProductsList } from "../../Services/BlipFoodsApi";
import { ProductItem } from "./ProductItem";
import "./style.scss";
import { setTrack } from "../../Services/Tracking";
import { TRACKINGS } from "../../Constants/Trackings";
import { HomeMessageMinimumPrice } from "../../Componentes/MessageMinimumPrice";
import { Chip, Divider } from "../../Componentes/Material";
import { Skeleton } from "@mui/material";
import { useUserStore } from "../../Zustand/User";
import { useMenuStore } from "../../Zustand/Menu";
import { MenuSkeleton } from "./MenuSkeleton";

export const Menu = () => {
  const [user] = useUserStore((state) => [state.user]);
  const [menu, updateMenu] = useMenuStore((state) => [state.menu, state.updateMenu]);

  useEffect(() => {
    if (menu.length === 0) {
      getProductsList(user.token).then((res) => {
        let categoryList = res.data.filter((category) => category.products.length > 0);
        updateMenu(categoryList);
        setTrack(
          TRACKINGS.showCatalog,
          "Quantidade",
          user.contactIdentity,
          user.botKey,
          user.adName
        );
      });
    }
  }, [user]);

  const RenderProductList = () => {
    return (
      <div className="spaces">
        {menu?.map((category, index) => {
          return (
            <div key={index}>
              <Divider textAlign="center" className="category-name">
                <Chip
                  label={category.name}
                  style={{ backgroundColor: "$surface-color-3" }}
                />
              </Divider>
              {category.products.map((product, index) => (
                <div key={index}>
                  <ProductItem
                    description={product.description}
                    id={product.id}
                    peopleServed={product.peopleToServe}
                    price={product.value}
                    productImage={product.imageUrl}
                    salePrice={product.salePrice}
                    title={product.name}
                  />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="products-container">
      <HomeMessageMinimumPrice />
      {menu?.length > 0 ? <RenderProductList /> : <MenuSkeleton />}
    </div>
  );
};
