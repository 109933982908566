export const getPeopleServed = (peopleServed) => {
  return peopleServed === 1 ? "Serve 1 pessoa" : "Serve " + peopleServed + " pessoas";
};

export const convertToReal = (priceNumber) => {
  if (priceNumber) {
    return priceNumber.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }
  return "";
};

export const getUrlParams = (merchantId, contactIdentity, adName) => {
  return `/?merchantId=${merchantId}&contactIdentity=${contactIdentity.replace(
    "@wa.gw.msging.net",
    ""
  )}${adName ? "&ad=" + adName : ""}`;
};

export const updatePrice = (price, isBeingAdded, productTotalPrice) => {
  if (isBeingAdded) {
    return productTotalPrice + price;
  } else {
    return productTotalPrice - price;
  }
};
