import "./style.scss";
import { Chip, Divider, Skeleton } from "../../../Componentes/Material";
import { ProductItem } from "../ProductItem";

export const MenuSkeleton = () => {
  return (
    <div className="spaces">
      <Divider textAlign="center" className="category-name">
        <Chip style={{ backgroundColor: "$surface-color-3" }} label="        " />
      </Divider>
      <ProductItem />
      <ProductItem />
      <Divider textAlign="center" className="category-name">
        <Chip style={{ backgroundColor: "$surface-color-3" }}>
          <Skeleton />
        </Chip>
      </Divider>
      <ProductItem />
    </div>
  );
};
