import React, { useState } from "react";
import { TextField } from "../Material";

export const TextArea = (props) => {
  const [inputText, setInputText] = useState(props?.value ? props?.value : "");

  const handleClick = (text) => {
    setInputText(text);
    props.emitChange(text);
  };

  return (
    <TextField
      size="small"
      inputProps={{
        maxLength: props.maxLength,
      }}
      helperText={`${inputText.length} / ${props.maxLength}`}
      multiline
      maxRows={props.maxRows}
      fullWidth
      label={props.label}
      placeholder={props.placeholder}
      value={inputText}
      onChange={(evt) => handleClick(evt.target.value)}
    />
  );
};
