import React from "react";
import "./style.scss";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Stack,
} from "../Material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmDialogPriceMinimum = (props) => {
  return (
    <div>
      <Dialog
        open={props.openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="title-dialog">
          <DialogTitle>{props.title}</DialogTitle>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
        <div className="buttons-dialog">
          <Stack spacing={2}>
            <Button variant="contained" onClick={props.handleAgreeEvent}>
              ESCOLHER MAIS ITENS
            </Button>
            <Button onClick={props.handleDisagreeEvent}>VOLTAR</Button>
          </Stack>
        </div>
      </Dialog>
    </div>
  );
};
