export const COLOR_PRIMARY = "#1e6bf1";
export const COLOR_PRIMARY_DARK = "#125ad5";
export const COLOR_CONTENT_DEFAULT = "#292929";
export const COLOR_CONTENT_DISABLE = "#666666";
// Background
export const SURFACE_COLOR_2 = "#f5f5f5";
export const SURFACE_COLOR_1 = "#ffffff";
export const SURFACE_COLOR_3 = "#e0e0e0";
export const SURFACE_COLOR_4 = "#0A0A0A";
export const SURFACE_COLOR_dark = "#292929";

export const ERROR = "#d32f2f";
