import React, { useEffect, useState } from "react";
import "./style.scss";
import { ThemeProvider } from "@emotion/react";
import blipFoodsLogo from "../../../Assets/img/blipfoods.svg";
import pagBankLogo from "../../../Assets/img/pagbank.svg";
import { useSearchParams } from "react-router-dom";
import {
  getMerchantInfo,
  getOrder,
  getTemporaryToken,
} from "../../../Services/BlipFoodsApi";
import { AKV } from "../../../Constants/Keys";
import { convertToReal } from "../../../Services/Common";
import { setTrack } from "../../../Services/Tracking";
import { useUserStore } from "../../../Zustand/User";
import {
  Avatar,
  LockOutlinedIcon,
  Stack,
  Typography,
  createTheme,
} from "../../../Componentes/Material";
import { COLOR_CONTENT_DEFAULT, COLOR_PRIMARY } from "../../../_tokens";

const PagseguroTheme = createTheme({
  typography: {
    allVariants: {
      color: COLOR_CONTENT_DEFAULT,
    },
  },
  palette: {
    primary: {
      main: COLOR_CONTENT_DEFAULT,
    },
    secondary: {
      main: COLOR_PRIMARY,
    },
  },
});

export const PagSeguroBase = (props) => {
  const [merchantInfo, setMerchantInfo] = useState();
  const [searchParams] = useSearchParams();
  const [orderInfo, setOrderInfo] = useState();
  const [user] = useUserStore((state) => [state.user]);

  useEffect(() => {
    const merchantId = searchParams.get("merchantId");
    const orderId = searchParams.get("orderId");

    getTemporaryToken(AKV.REACT_APP_BLIP_FOODS_TOKEN).then((res) => {
      getMerchantInfo(merchantId, res.data.temporaryToken).then((merchant) => {
        merchant = merchant.data;
        setMerchantInfo({
          name: merchant.name,
          image: merchant.imageUrl,
        });
        getOrder(merchant.token, orderId).then((order) => setOrderInfo(order.data));
      });
    });
  }, []);

  useEffect(() => {
    if (user.botKey !== "") {
      setTrack(
        "Pagamento pagseguro exibicao",
        `Quantidade`,
        user.contactIdentity,
        user.botKey,
        user.adName
      );
    }
  }, [user]);

  const Footer = () => {
    return (
      <Stack direction="row" className="footer">
        <Stack spacing={1} className="padding-left">
          <Typography className="italic bold" variant="caption">
            Site criado por:
          </Typography>
          <img src={blipFoodsLogo} className="logo" />
        </Stack>
        <Stack spacing={1} className="padding-right">
          <Typography className="italic bold" variant="caption">
            Pagamento processado via:
          </Typography>
          <img src={pagBankLogo} className="logo" />
        </Stack>
      </Stack>
    );
  };

  return (
    <ThemeProvider theme={PagseguroTheme}>
      <div className="fullscreen">
        <Stack direction="row" spacing={1} className="header-payment">
          <LockOutlinedIcon />
          <Typography variant="body1">Ambiente seguro</Typography>
        </Stack>
        <Stack spacing={2} className="payment-info">
          <Stack alignItems="center">
            <Typography variant="body2" className="thin">
              Pagando para:
            </Typography>
            <Avatar
              className="avatar-style"
              edge="start"
              alt="Logotipo do merchant"
              src={merchantInfo?.image}
            />
            <Typography variant="body1" className="bold">
              {merchantInfo?.name}
            </Typography>
          </Stack>
          <Typography variant="h4" className="bold">
            {convertToReal(orderInfo?.totalPrice)}
          </Typography>
        </Stack>
        <Stack className="type-payment" spacing={2}>
          {props.children}
        </Stack>
        <Footer />
      </div>
    </ThemeProvider>
  );
};
