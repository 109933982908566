import "./style.scss";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { convertToReal, getPeopleServed, getUrlParams } from "../../../Services/Common";
import {
  Card,
  Chip,
  LocalDiningRoundedIcon,
  Typography,
} from "../../../Componentes/Material";
import { Skeleton } from "@mui/material";
import { useUserStore } from "../../../Zustand/User";

export const ProductItem = ({
  id,
  title,
  description,
  peopleServed,
  salePrice,
  price,
  productImage,
}) => {
  const navigate = useNavigate();
  const [user] = useUserStore((state) => [state.user]);

  const handleClick = () => {
    navigate(
      `detail/${id}${getUrlParams(user.merchantId, user.contactIdentity, user.adName)}`
    );
  };

  const Price = ({ value }) => {
    return (
      <Typography className="price" variant="subtitle2" color="primary">
        {convertToReal(value)}
      </Typography>
    );
  };

  const SalePrice = () => {
    return (
      <div className="price-container">
        <Typography className="original-price" variant="caption">
          {convertToReal(price)}
        </Typography>

        <Price value={salePrice} />
      </div>
    );
  };

  const RenderPrice = () => {
    return salePrice > 0 ? <SalePrice /> : <Price value={price} />;
  };

  const RenderPeopleServed = () => {
    return peopleServed === 0 ? (
      <></>
    ) : (
      <Chip
        size="small"
        icon={<LocalDiningRoundedIcon />}
        label={getPeopleServed(peopleServed)}
      />
    );
  };

  const RenderProduct = () => {
    return (
      <Card className="product-card fade-in" onClick={() => handleClick()}>
        <div className="product-item">
          <div className="information">
            <Typography className="title" variant="body2" gutterBottom>
              {title}
            </Typography>

            <Typography className="description" variant="caption" gutterBottom>
              {description}
            </Typography>
            <div className="bottom-info-product">
              <div className="people-served-container">
                <RenderPeopleServed />
              </div>
              <RenderPrice />
            </div>
          </div>

          <img className="image" alt="Foto do produto" src={productImage}></img>
        </div>
      </Card>
    );
  };

  const RenderSkeleton = () => {
    return (
      <Card className="product-card">
        <div className="product-item">
          <div className="information">
            <Typography className="title" variant="body2" gutterBottom>
              <Skeleton width={140} />
            </Typography>

            <Typography className="description" variant="caption" gutterBottom>
              <Skeleton />
            </Typography>
            <div className="bottom-info-product">
              <div className="people-served-container">
                <Skeleton height={20} width={70} />
              </div>
              <Skeleton height={20} width={50} />
            </div>
          </div>
          <div className="skeleton-image">
            <Skeleton height={200} width={"100%"} />
          </div>
        </div>
      </Card>
    );
  };

  return id ? <RenderProduct /> : <RenderSkeleton />;
};
