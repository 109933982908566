import React, { useContext, useState } from "react";
import "./style.scss";

import { convertToReal, getUrlParams } from "../../Services/Common";
import { getPeopleServed } from "../../Services/Common";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "../ConfirmDialog";
import { QuantitySelector } from "../QuantitySelector";
import { Card, Grid, Typography } from "../Material";
import { useCartStore } from "../../Zustand/Cart";
import { useUserStore } from "../../Zustand/User";
import { ADD_1, SUBSTRACT_1 } from "../../Constants/Groups";
import { PRODUCTS_ATTRIBUTE, TOTAL_VALUE_ATTRIBUTE } from "../../Constants/zustand";

export const CartItem = (props) => {
  const [cart, changeProductQuantity, setCartAttribute] = useCartStore((state) => [
    state.cart,
    state.changeProductQuantity,
    state.setCartAttribute,
  ]);
  const [user] = useUserStore((state) => [state.user]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const itemQuantityChanger = (quantity, value) => {
    changeProductQuantity(props.itemIndex, quantity);
    changeCartValue(value);
  };

  const changeCartValue = (value) => {
    value = cart.totalValue + value;
    setCartAttribute(TOTAL_VALUE_ATTRIBUTE, value);
  };

  const editItemQuantity = (action, value) => {
    if (action === "add") {
      itemQuantityChanger(ADD_1, value);
    } else {
      if (cart.products[props.itemIndex]?.quantity === 1) {
        setOpenDialog(true);
      } else {
        itemQuantityChanger(SUBSTRACT_1, -value);
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDisagreeEvent = () => {
    handleDialogClose();
  };

  const handleAgreeEvent = () => {
    handleDialogClose();
    const value = cart.totalValue - cart.products[props.itemIndex].totalValue;
    const updatedProducts = cart.products;
    updatedProducts.splice(props.itemIndex, 1);
    setCartAttribute(PRODUCTS_ATTRIBUTE, updatedProducts);
    setCartAttribute(TOTAL_VALUE_ATTRIBUTE, value);
  };

  const goToDetails = () => {
    navigate(
      `/detail/${props.id}${getUrlParams(
        user.merchantId,
        user.contactIdentity,
        user.adName
      )}`
    );
  };

  return (
    <Card>
      <ConfirmDialog
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        handleAgreeEvent={handleAgreeEvent}
        handleDisagreeEvent={handleDisagreeEvent}
        title=""
        text="Esse produto será removido do carrinho. Deseja continuar?"
      />
      <Grid container spacing={2}>
        <Grid item xs={3} onClick={() => goToDetails()}>
          <img
            className="item-image"
            src={props.imageUri}
            alt="Foto ilustrativa do produto"
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container p={1} gap={"4px"}>
            <Grid item xs={12} pt={1} pr={2} onClick={() => goToDetails()}>
              <Typography variant="body2" display="block" className="item-name">
                {props.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {props.extras.map((extra) => {
                  return (
                    <Grid item xs={12}>
                      <Typography variant="caption" color="colorContentDisabled">
                        {`${extra?.quantity}x ${extra?.name}`}
                        {extra.price > 0 ? ` - ${convertToReal(extra?.price)}` : ""}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item xs={12} pr={2} onClick={() => goToDetails()}>
              <div className="bottom-info">
                {props.peopleServided > 0 ? (
                  <Typography variant="caption" display="block" className="portions">
                    {getPeopleServed(props.peopleServided)}
                  </Typography>
                ) : (
                  <></>
                )}
                <div className="price-only">
                  <Typography
                    variant="subtitle2"
                    display="block"
                    gutterBottom
                    color="primary"
                  >
                    <b>{convertToReal(props.value)}</b>
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} pr={2}>
              <div className="center-item-container">
                <QuantitySelector
                  increaseEvent={() => editItemQuantity("add", props.value)}
                  decreaseEvent={() => editItemQuantity("remove", props.value)}
                  quantity={props.quantity}
                  removable={true}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
