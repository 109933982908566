import { create } from "zustand";

const emptyCartDetails = {
  products: [],
  totalValue: 0,
  comment: "",
  totalQuantity: 0,
};

export const useCartStore = create((set) => ({
  cart: emptyCartDetails,

  updateCart: (cart) => {
    set(() => ({ cart }));
  },
  removeCartProduct: (index) => {
    set((state) => ({
      cart: { ...state.cart, products: state.cart.products.splice(index, 1) },
    }));
  },
  addCartProduct: (product) => {
    set((state) => ({
      cart: { ...state.cart, products: [...state.cart.products, product] },
    }));
  },
  changeProductQuantity: (index, quantity) => {
    set((state) => ({
      cart: {
        ...state.cart,
        products: [
          ...state.cart.products.slice(0, index),
          {
            ...state.cart.products[index],
            quantity: state.cart.products[index].quantity + quantity,
          },
          ...state.cart.products.slice(index + 1),
        ],
      },
    }));
  },
  setCartAttribute: (attribute, value) => {
    set((state) => ({ cart: { ...state.cart, [attribute]: value } }));
  },
}));
