import axios from "axios";
import { AKV } from "../Constants/Keys";

axios.defaults.baseURL = AKV.REACT_APP_API_URL;

export const getTemporaryToken = (staticToken) => {
  var postData = {
    staticToken: staticToken,
  };

  return axios.post("/api/v1/authentication", postData, {
    headers: {
      "content-type": "application/json,charset=UTF-8",
    },
  });
};

export const sendCart = (contactIdentity, merchantId, comment, items, staticToken) => {
  return getTemporaryToken(staticToken).then((res) => {
    addAuthToken(res.data.temporaryToken);

    return axios.post("/api/v1/cart", {
      contactIdentity,
      merchantId,
      comment,
      orderItems: items,
    });
  });
};

export const getMerchantInfo = (merchantId, tempToken) => {
  addAuthToken(tempToken);
  return axios.get("/api/v1/merchant", { params: { merchantId } });
};

export const getProductInfo = async (productId, staticToken) => {
  const { data } = await getTemporaryToken(staticToken).then((res) => {
    addAuthToken(res.data.temporaryToken);
    return axios.get(`/api/v1/product/${productId}`);
  });

  const groupList = data.groupViewDto.map((group) => {
    return {
      ...group,
      error: false,
    };
  });
  return {
    ...data,
    quantity: 1,
    groupViewDto: groupList,
  };
};

export const getProductsList = (staticToken) => {
  return getTemporaryToken(staticToken).then((res) => {
    addAuthToken(res.data.temporaryToken);
    axios.defaults.headers["includeProducts"] = true;
    return axios.get(`/api/v1/category/`);
  });
};

export const payWithPSCard = (
  staticToken,
  orderId,
  cpf,
  cardEncrypted,
  holdName,
  securityCode
) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.temporaryToken}`;
    return axios.post("/api/v1/pagseguro/payment-credit-card", {
      orderId,
      cpf,
      cardEncrypted,
      holdName,
      securityCode,
    });
  });
};

export const getOrder = (staticToken, orderId) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.temporaryToken}`;
    return axios.get(`/api/v1/order/${orderId}`);
  });
};

export const payWithCodePix = (staticToken, orderId, cpf) => {
  return getTemporaryToken(staticToken).then((res) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.temporaryToken}`;
    return axios.post("/api/v1/pagseguro/payment-pix", {
      orderId,
      cpf,
    });
  });
};

const addAuthToken = (temporaryToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${temporaryToken}`;
};
