import React from "react";
import { convertToReal, updatePrice } from "../../../Services/Common";
import "./style.scss";
import { Box, Checkbox, Divider, Grid, Typography } from "../../../Componentes/Material";
import { useDetailStore } from "../../../Zustand/ItemDetail";

export const MultipleChoices = (props) => {
  const [product, setProduct] = useDetailStore((state) => [
    state.product,
    state.updateProduct,
  ]);

  const handleCheckEvent = (isBeingAdded, itemIndex, price) => {
    const updatedProduct = product;
    price = updatePrice(price, isBeingAdded, product.totalValue);
    updatedProduct.totalValue = price;
    updatedProduct.groupViewDto[props.groupIndex].groupItemViewDto[itemIndex].isSelected =
      isBeingAdded;
    setProduct(updatedProduct);
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <Grid item xs={12}>
        <div
          className="side-by-side-container"
          style={{ justifyContent: "space-between" }}
        >
          <Typography variant="body2" color={props?.error ? "errorColor" : "primary"}>
            <strong>{props?.title}</strong>
          </Typography>
          <Typography
            variant="caption"
            color={props?.error ? "errorColor" : "colorContentDisabled"}
          >
            {props?.subtitle}
          </Typography>
        </div>

        <Grid item xs={12}>
          <Divider color={props?.error ? "errorColor" : "primary"} />
        </Grid>
        <Grid item xs={12}>
          {props?.items?.map((item, index) => {
            return (
              <Box
                key={index}
                component="div"
                marginTop={"10px"}
                onClick={() => handleCheckEvent(!item?.isSelected, index, item.price)}
              >
                <div className="side-by-side-container">
                  <div>
                    <Typography variant="body2" className="multiple-item-name">
                      {item.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="#6E7B91"
                      className="multiple-item-description"
                    >
                      {item.description}
                    </Typography>
                    {item.price > 0 ? (
                      <Typography variant="caption" color="primary">
                        <strong>+ {convertToReal(item?.price)}</strong>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </div>
                  <Checkbox checked={item.isSelected}></Checkbox>
                </div>
                <Box component="div" marginTop={"10px"}>
                  <Divider />
                </Box>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
