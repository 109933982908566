import React, { useEffect } from "react";
import "./style.scss";
import { Button, Typography, Stack } from "@mui/material";
import { PagSeguroBase } from "../PagSeguroBase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setTrack } from "../../../Services/Tracking";
import { useUserStore } from "../../../Zustand/User";
import { useSnackbar } from "notistack";

export const ExpiredPix = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get("merchantId");
  const orderId = searchParams.get("orderId");
  const userCpf = searchParams.get("cpf");
  const contactIdentity = searchParams.get("contactIdentity");
  const [user] = useUserStore((state) => [state.user]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTrack(
      "Pagamento pagseguro pix expirado exibicao",
      `Quantidade`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    enqueueSnackbar("O código do Pix expirou.", { variant: "error" });
  }, []);

  const generateNewCode = () => {
    setTrack(
      "Pagamento pagseguro pix expirado selecao",
      `Gerar novo Pix`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    navigate(
      `/payment/pix?merchantId=${merchantId}&orderId=${orderId}&cpf=${userCpf}&contactIdentity=${contactIdentity}`
    );
  };

  const changePaymentType = () => {
    setTrack(
      "Pagamento pagseguro pix expirado selecao",
      `Mudar meio de pagamento`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    navigate(
      `/payment?merchantId=${merchantId}&orderId=${orderId}&contactIdentity=${contactIdentity}`
    );
  };
  return (
    <PagSeguroBase>
      <Stack spacing={4} className="pix-info-container">
        <Typography alignSelf="center">Seu pix expirou.</Typography>
        <Typography textAlign="center">
          Mas não se preocupe, você pode gerar um novo código.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          className="card-continue-btn"
          onClick={() => generateNewCode()}
        >
          Gerar novo Pix
        </Button>
        <Typography
          onClick={() => changePaymentType()}
          textAlign="center"
          color="secondary"
        >
          Mudar meio de pagamento
        </Typography>
      </Stack>
    </PagSeguroBase>
  );
};
