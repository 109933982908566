import React, { useContext, useEffect, useState } from "react";
import { getProductInfo } from "../../Services/BlipFoodsApi";
import "./style.scss";
import { getPeopleServed, getUrlParams } from "../../Services/Common";
import { useNavigate, useParams } from "react-router-dom";
import { setTrack } from "../../Services/Tracking";
import { TRACKINGS } from "../../Constants/Trackings";
import { TextArea } from "../../Componentes/TextArea";
import { Extras } from "../../Componentes/Groups/Extras";
import { MultipleChoices } from "../../Componentes/Groups/MultipleChoices";
import { OneChoice } from "../../Componentes/Groups/OneChoice";
import {
  Box,
  CloseFullscreenRoundedIcon,
  Grid,
  OpenInFullRoundedIcon,
  Typography,
} from "../../Componentes/Material";
import { useDetailStore } from "../../Zustand/ItemDetail";
import { BottomOptions } from "./BottomOptions";
import { useCartStore } from "../../Zustand/Cart";
import { useUserStore } from "../../Zustand/User";
import { useSnackbar } from "notistack";
import { DetailSkeleton } from "./DetailSkeleton";

export const Detail = () => {
  const [showAddProductButton, setShowAddProductButton] = useState(true);
  const [user] = useUserStore((state) => [state.user]);
  const { productId } = useParams();
  const navigate = useNavigate();
  const groupErrors = [];
  const [expandImage, setExpandImage] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct, removeProduct, setProductAttribute, setGroupError] =
    useDetailStore((state) => [
      state.product,
      state.updateProduct,
      state.removeProduct,
      state.setProductAttribute,
      state.setGroupError,
    ]);
  const [cart, setCartAttribute, addCartProduct] = useCartStore((state) => [
    state.cart,
    state.setCartAttribute,
    state.addCartProduct,
  ]);

  useEffect(() => {
    getProductInfo(productId, user.token).then((res) => {
      res.totalValue = res.salePrice > 0 ? res.salePrice : res.value;
      setProduct(res);

      setTrack(
        TRACKINGS.showItem,
        res.name,
        user.contactIdentity,
        user.botKey,
        user.adName
      );
    });
  }, [user]);

  useEffect(() => {
    return () => {
      removeProduct();
    };
  }, []);

  const handleAddProduct = () => {
    checkErrors();
    const hasAnyError = groupErrors.some((item) => item === true);
    if (!hasAnyError) {
      addProductToCart();
    }
  };

  const setErrors = (groupErrors) => {
    groupErrors.forEach((status, position) => {
      setGroupError(status, position);
    });
  };

  const showGroupErrorToast = (groupTitles) => {
    if (groupTitles.length > 0) {
      const formattedGroupTitles =
        groupTitles.length > 1
          ? `${groupTitles.slice(0, -1).join(", ")} e ${groupTitles.slice(-1)}`
          : { 0: "", 1: groupTitles[0] }[groupTitles.length];

      enqueueSnackbar(`É obrigatória a seleção de item em: ${formattedGroupTitles}`, {
        variant: "error",
      });
    }
  };

  const checkErrors = () => {
    const groupErrorTitles = [];
    product.groupViewDto.forEach((group) => {
      if (group.groupType === "OneChoice") {
        const status = group.groupItemViewDto.some((item) => item.isSelected === true);
        groupErrors.push(!status);
        if (!status) {
          groupErrorTitles.push(group.title);
        }
      } else if (group.groupType === "MultipleChoice") {
        let itemCounter = 0;

        group.groupItemViewDto.forEach((item) => {
          if (item.isSelected === true) {
            itemCounter += 1;
          }
        });

        if (group.subtitle.includes("Obrigatório")) {
          if (
            (itemCounter >= group.minQuantity && itemCounter <= group.maxQuantity) ||
            (itemCounter >= group.minQuantity && group.maxQuantity === 0)
          ) {
            groupErrors.push(false);
          } else {
            groupErrors.push(true);
            groupErrorTitles.push(group.title);
          }
        } else {
          // * caso não seja obrigatório só checamos se o usuário não selecionou mais que o máximo
          if (itemCounter <= group.maxQuantity || group.maxQuantity === 0) {
            groupErrors.push(false);
          } else {
            groupErrors.push(true);
            groupErrorTitles.push(group.title);
          }
        }
      } else if (group.groupType === "Extras") {
        let itemCounter = 0;
        if (group.maxQuantity === 0) {
          groupErrors.push(false);
        } else {
          group.groupItemViewDto.forEach((item) => {
            itemCounter += item.quantity;
          });
          if (itemCounter <= group.maxQuantity && itemCounter >= group.minQuantity) {
            groupErrors.push(false);
          } else {
            groupErrors.push(true);
            groupErrorTitles.push(group.title);
          }
        }
      }
    });

    setErrors(groupErrors);
    showGroupErrorToast(groupErrorTitles);
  };

  const getSelectGroupItemsTrackings = () => {
    let tracking = "Sem Complemento";
    let selectedGroupItems = "";

    product.groupViewDto.forEach((group) => {
      let selectedItems = group.groupItemViewDto.filter(
        (item) => item.isSelected === true
      );

      if (selectedItems.length > 0) {
        tracking = "Com complemento";
        selectedGroupItems =
          selectedGroupItems === ""
            ? (selectedGroupItems += group.title + " | ")
            : (selectedGroupItems += " || " + group.title + " | ");

        selectedItems = selectedItems.map((item, index) => {
          let quantity = item.quantity > 0 ? item.quantity : 1;
          if (selectedItems.length - 1 === index) {
            selectedGroupItems += item.name + " | " + quantity + " | " + item.price;
          } else {
            selectedGroupItems +=
              item.name + " | " + quantity + " | " + item.price + " | ";
          }
        });
      }
    });

    if (tracking === "Com complemento") {
      setTrack(
        TRACKINGS.complementItem,
        `${selectedGroupItems}`,
        user.contactIdentity,
        user.botKey,
        user.adName
      );
    }

    return tracking;
  };

  const getSelectGroupItems = (groupList) => {
    let groupObj = [];
    let extras = [];
    groupList.forEach((group) => {
      let selectedItems = group.groupItemViewDto.filter(
        (item) => item.isSelected === true
      );
      if (selectedItems.length > 0) {
        selectedItems = selectedItems.map((item) => {
          extras.push({
            name: item.name,
            quantity: group.groupType === "Extras" ? item.quantity : 1,
            price: item.price,
          });
          return {
            id: item.id,
            quantity: group.groupType === "Extras" ? item.quantity : 1,
          };
        });
        groupObj.push({ id: group.id, cartGroupItemDto: selectedItems });
      }
    });
    return { groupObj, extras };
  };

  const addProductToCart = () => {
    let trackingValue = getSelectGroupItemsTrackings();

    setTrack(
      TRACKINGS.addToCartSelect,
      `${product.name} | ${product.totalValue} | ${product.quantity} | ${trackingValue}`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );

    setTrack(
      TRACKINGS.actionButtonsShow,
      "Quantidade",
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    const extrasInfo = getSelectGroupItems(product.groupViewDto);
    product.cartGroupDto = extrasInfo.groupObj;
    product.extras = extrasInfo.extras;

    const cartValue =
      +cart.totalValue.toFixed(2) + +(product.totalValue * product.quantity).toFixed(2);
    addCartProduct(product);
    setCartAttribute("totalValue", cartValue);
    setShowAddProductButton(false);
    enqueueSnackbar("Produto adicionado ao carrinho!", { variant: "success" });
  };

  const handleActionClick = (route) => {
    const routeName = route === "cart" ? "/cart" : "";
    const trackValue = route === "cart" ? "Fechar pedido" : "Continuar navegando";

    setTrack(
      TRACKINGS.actionButtonsSelect,
      trackValue,
      user.contactIdentity,
      user.botKey,
      user.adName
    );

    navigate(
      `${routeName}${getUrlParams(user.merchantId, user.contactIdentity, user.adName)}`
    );
  };

  const ImageButton = () => {
    return expandImage ? (
      <CloseFullscreenRoundedIcon className="image-button" />
    ) : (
      <OpenInFullRoundedIcon className="image-button" />
    );
  };

  return (
    <div className="info-detail-product">
      {product?.name ? (
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Box
              className={`image-container ${expandImage ? "expand-image" : ""}`}
              onClick={() => setExpandImage(!expandImage)}
            >
              <img className="image-detail" src={product?.imageUrl} alt="produto" />
              <ImageButton />
            </Box>
          </Grid>
          <Grid container gap={"20px"}>
            <Grid item xs={12}>
              <Grid container gap={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{product?.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="colorContentDisabled">
                    {product?.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {product?.groupViewDto?.map((item, index) => {
              if (item?.groupType === "Extras") {
                return (
                  <Grid item xs={12} key={index}>
                    <Extras
                      error={item?.error}
                      items={item?.groupItemViewDto}
                      title={item?.title}
                      subtitle={item?.subtitle}
                      key={item?.id}
                      groupIndex={index}
                      maxQuantity={item?.maxQuantity}
                    />
                  </Grid>
                );
              } else if (item?.groupType === "OneChoice") {
                return (
                  <Grid item xs={12} key={index}>
                    <OneChoice
                      error={item?.error}
                      nameGroup={item?.title}
                      groupItems={item?.groupItemViewDto}
                      groupListIndex={index}
                      key={item?.id}
                    />
                  </Grid>
                );
              } else if (item?.groupType === "MultipleChoice") {
                return (
                  <Grid item xs={12} key={index}>
                    <MultipleChoices
                      error={item?.error}
                      selected={item?.isSelected}
                      items={item?.groupItemViewDto}
                      title={item?.title}
                      subtitle={item?.subtitle}
                      key={item?.id}
                      groupIndex={index}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} key={index}>
                    <TextArea
                      maxRows={3}
                      label={item?.title}
                      placeholder={item?.groupItemViewDto[0].name}
                      maxLength={255}
                      emitChange={(value) => setProductAttribute("comment", value)}
                      key={item?.id}
                    />
                  </Grid>
                );
              }
            })}

            <Grid item xs={12} mt={2}>
              <BottomOptions
                addProductEvent={() => handleAddProduct()}
                buyMoreEvent={() => handleActionClick("")}
                finishOrderEvent={() => handleActionClick("cart")}
                peopleToServe={getPeopleServed(product?.peopleToServe)}
                showAddProductButton={showAddProductButton}
              ></BottomOptions>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <DetailSkeleton />
      )}
    </div>
  );
};
