import { create } from "zustand";

const emptyProductDetails = {
  groupViewDto: [],
  categories: "",
  comment: "",
  description: "",
  id: "",
  imageUrl: "",
  isActive: true,
  name: "",
  peopleToServe: 0,
  quantity: 0,
  value: 0,
  totalValue: 0,
  totalQuantity: 0,
};

export const useDetailStore = create((set) => ({
  // * Produto
  product: emptyProductDetails,

  // * Manipulação de produtos e derivados
  updateProduct: (product) => {
    set(() => ({ product }));
  },
  removeProduct: () => {
    set(() => ({ product: emptyProductDetails }));
  },
  removeProductUnit: () => {
    set((state) => ({
      product: { ...state.product, quantity: state.product.quantity - 1 },
    }));
  },
  addProductUnit: () => {
    set((state) => ({
      product: { ...state.product, quantity: state.product.quantity + 1 },
    }));
  },
  setProductAttribute: (attribute, value) => {
    set((state) => ({ product: { ...state.product, [attribute]: value } }));
  },
  setGroupError: (status, position) => {
    set((state) => ({
      product: {
        ...state.product,
        groupViewDto: [
          ...state.product.groupViewDto.slice(0, position),
          {
            ...state.product.groupViewDto[position],
            error: status,
          },
          ...state.product.groupViewDto.slice(position + 1),
        ],
      },
    }));
  },
}));
