import React from "react";
import {
  Button,
  Paper,
  ShoppingCartIcon,
  Slide,
  Typography,
} from "../../../Componentes/Material";
import { QuantitySelector } from "../../../Componentes/QuantitySelector";
import { useDetailStore } from "../../../Zustand/ItemDetail";
import { convertToReal } from "../../../Services/Common";

export const BottomOptions = ({
  peopleToServe,
  addProductEvent,
  buyMoreEvent,
  finishOrderEvent,
  showAddProductButton,
}) => {
  const [product, removeProductUnit, addProductUnit] = useDetailStore((state) => [
    state.product,
    state.removeProductUnit,
    state.addProductUnit,
  ]);
  const Price = ({ value }) => {
    return (
      <div className="detail-price-container">
        <Typography className="price" variant="subtitle2" color="primary">
          {convertToReal(value)}
        </Typography>
      </div>
    );
  };

  const SalePrice = ({ price, salePrice }) => {
    return (
      <div className="detail-price-container">
        <Typography className="original-price" variant="caption">
          {convertToReal(price)}
        </Typography>

        <Price value={salePrice} />
      </div>
    );
  };

  const RenderPrice = () => {
    const currentPriceValue = convertToReal(product?.quantity * product?.totalValue);

    return product?.salePrice > 0 ? (
      <SalePrice price={product?.value} salePrice={currentPriceValue} />
    ) : (
      <Price value={currentPriceValue} />
    );
  };

  return (
    <Slide className="bottom-options" direction="up" in={true} mountOnEnter unmountOnExit>
      <Paper elevation={3}>
        {product?.peopleToServe === 0 || product?.peopleToServe === undefined ? (
          <div className="price-with-out-people-to-serve">
            <RenderPrice />
          </div>
        ) : (
          <div className="item-info">
            <Typography variant="caption" color="text.primary">
              {peopleToServe}
            </Typography>
            <RenderPrice />
          </div>
        )}
        {showAddProductButton === true ? (
          <div className="detail-item-container">
            <QuantitySelector
              increaseEvent={() => addProductUnit()}
              decreaseEvent={() => removeProductUnit()}
              minQuantity={1}
              maxQuantity={1000}
              quantity={product?.quantity}
              removable={true}
            />
            <div>
              <Button variant="contained" size="small" onClick={() => addProductEvent()}>
                Adicionar ao carrinho
              </Button>
            </div>
          </div>
        ) : (
          <div className="detail-item-container">
            <div>
              <Button
                variant="outlined"
                size="small"
                className="button-continue"
                onClick={() => buyMoreEvent()}
              >
                Comprar mais
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                startIcon={<ShoppingCartIcon />}
                onClick={() => finishOrderEvent()}
              >
                Fechar pedido
              </Button>
            </div>
          </div>
        )}
      </Paper>
    </Slide>
  );
};
