import React, { useState } from "react";
import "./style.scss";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PixIcon from "@mui/icons-material/Pix";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PagSeguroBase } from "./PagSeguroBase";
import { setTrack } from "../../Services/Tracking";
import { useUserStore } from "../../Zustand/User";

export const PagSeguro = () => {
  const [paymentMode, setPaymentMode] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get("merchantId");
  const orderId = searchParams.get("orderId");
  const contactIdentity = searchParams.get("contactIdentity");
  const [userCpf, setUserCpf] = useState("");
  const [user] = useUserStore((state) => [state.user]);

  const RenderPaymentOption = () => {
    if (paymentMode === "PIX") {
      return (
        <Stack spacing={2} className="payment-buttons-container">
          <Typography textAlign="center" className="bold" variant="subtitle2">
            <b>Informe seu CPF:</b>
          </Typography>
          <Typography className="italic" textAlign="center" variant="caption">
            Ele será usado para gerar o código de pagamento pelo PagSeguro.
          </Typography>
          <TextField
            required
            maxRows={1}
            placeholder="000.000.000-00"
            autoFocus={true}
            inputProps={{ maxLength: 14 }}
            value={userCpf}
            onInput={(event) => handleCpfChange(event?.target?.value)}
          ></TextField>
          <Button
            variant="contained"
            color="secondary"
            disabled={!cpfIsValid(userCpf)}
            className="card-continue-btn"
            onClick={() => btnPixMode()}
          >
            Continuar
          </Button>
        </Stack>
      );
    } else if (paymentMode === "CARTAO") {
      return (
        <Button
          variant="contained"
          color="secondary"
          className="card-continue-btn"
          onClick={() => handleCardSelection()}
        >
          Continuar
        </Button>
      );
    }
    return <></>;
  };

  const handleCardSelection = () => {
    setTrack(
      "Pagamento pagseguro cartao continuar",
      `Quantidade`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    navigate(
      `/payment/card?merchantId=${merchantId}&orderId=${orderId}&contactIdentity=${contactIdentity}`
    );
  };

  const btnPixMode = () => {
    const CPF = userCpf.replace(".", "").replace(".", "").replace("-", "");
    setTrack(
      "Pagamento pagseguro pix continuar",
      `Quantidade`,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
    navigate(
      `/payment/pix?merchantId=${merchantId}&orderId=${orderId}&cpf=${CPF}&contactIdentity=${contactIdentity}`
    );
  };

  const btnClassHandler = (paymentType) => {
    if (paymentMode === paymentType) {
      return `payment-button btn-selected`;
    }
    return "payment-button";
  };

  const filterNumber = (value) => {
    return value.replace(/\D/g, "");
  };

  const cpfIsValid = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, "").padStart(11, "0");
    if (cpf === "") return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length !== 11 || cpf.split("").every((char) => char === cpf[0])) return false;
    // Valida 1o digito
    var add = 0;
    for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
  };

  const handleCpfChange = (CPFValue) => {
    CPFValue = filterNumber(CPFValue);
    CPFValue = CPFValue.replace(/(\d{3})(\d)/, "$1.$2"); // * Adiciona um ponto após os 3 primeiros números
    CPFValue = CPFValue.replace(/(\d{3})(\d)/, "$1.$2");
    CPFValue = CPFValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // * Hífen nos dois últimos
    setUserCpf(CPFValue.substring(0, 14));
  };

  const paymentBtnToggle = (paymentMode) => {
    if (paymentMode === "PIX") {
      setPaymentMode("PIX");
    } else {
      setPaymentMode("CARTAO");
    }
    setTrack(
      "Pagamento pagseguro selecao",
      paymentMode,
      user.contactIdentity,
      user.botKey,
      user.adName
    );
  };

  return (
    <PagSeguroBase>
      <Stack spacing={1} alignItems="center">
        <Typography variant="body1">Selecione a forma de pagamento:</Typography>
        <Stack direction="row" className="payment-buttons-container">
          <Button
            variant="outlined"
            startIcon={<PixIcon />}
            className={btnClassHandler("PIX")}
            onClick={() => paymentBtnToggle("PIX")}
          >
            Pix
          </Button>
          <Button
            variant="outlined"
            startIcon={<CreditCardIcon />}
            className={btnClassHandler("CARTAO")}
            onClick={() => paymentBtnToggle("CARTAO")}
          >
            Crédito
          </Button>
        </Stack>
      </Stack>
      <RenderPaymentOption />
    </PagSeguroBase>
  );
};
