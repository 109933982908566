import React from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { setTrack } from "../../Services/Tracking";
import { TRACKINGS } from "../../Constants/Trackings";
import { getUrlParams } from "../../Services/Common";
import { Button, ProductionQuantityLimits, Typography } from "../Material";
import { useUserStore } from "../../Zustand/User";

export const EmptyContent = (props) => {
  const navigate = useNavigate();
  const [user] = useUserStore((state) => [state.user]);

  const handleActionClick = (route) => {
    const trackValue = "Quantidade";
    route = getUrlParams(user.merchantId, user.contactIdentity, user.adName);

    setTrack(
      TRACKINGS.goToHomePage,
      trackValue,
      user.contactIdentity,
      user.botKey,
      user.adName
    );

    navigate(route);
  };

  return (
    <div className="empty-cart-container">
      <ProductionQuantityLimits className="empty-cart-icon" />
      <Typography className="empty-cart-text" variant="body2" gutterBottom>
        Nenhum produto no carrinho
      </Typography>
      <Button
        variant="contained"
        className="button-go-to-catalog"
        onClick={() => handleActionClick("/")}
      >
        Ir para o catálogo
      </Button>
    </div>
  );
};
