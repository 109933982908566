import { create } from "zustand";

const emptyUserDetails = {
  token: "",
  name: "",
  image: "",
  faviconBase64: "",
  merchantId: "",
  botKey: "",
  contactIdentity: "",
  adName: "",
  minimumPriceValue: "",
};

export const useUserStore = create((set) => ({
  user: emptyUserDetails,

  updateUser: (user) => {
    set(() => ({ user }));
  },

  setUserAttribute: (attribute, value) => {
    set((state) => ({ cart: { ...state.cart, [attribute]: value } }));
  },
}));
