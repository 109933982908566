import React from "react";
import { Button, ButtonGroup, Typography } from "../Material";

export const QuantitySelector = (props) => {
  return (
    <ButtonGroup variant="contained" size="small" disableElevation>
      <Button
        onClick={() => props.decreaseEvent()}
        disabled={props.quantity === props.minQuantity}
      >
        -
      </Button>
      <Typography variant="overline" gutterBottom className="item-quantity">
        {props.quantity}
      </Typography>
      <Button disabled={props.disabled} onClick={() => props.increaseEvent()}>
        +
      </Button>
    </ButtonGroup>
  );
};
