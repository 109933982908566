import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AKV } from "../../Constants/Keys";
import { getMerchantInfo, getTemporaryToken } from "../../Services/BlipFoodsApi";
import { Navbar } from "../Navbar";
import "./style.scss";
import { useUserStore } from "../../Zustand/User";
import { Box } from "../../Componentes/Material";

export const Layout = (props) => {
  const [updateUser] = useUserStore((state) => [state.updateUser]);
  const [searchParams] = useSearchParams();
  let contactIdentity = searchParams.get("contactIdentity");
  contactIdentity = `${contactIdentity}@wa.gw.msging.net`;
  const merchantId = searchParams.get("merchantId");
  const adName = searchParams.get("ad");

  useEffect(() => {
    getBasicInfo();
    insertHotjarScript();
  }, []);

  const insertHotjarScript = () => {
    if (!window.location.hostname.includes("hmg")) {
      var script = document.createElement("script");
      script.text = `(function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: 3163636, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`;
      document.head.appendChild(script);
    }
  };

  const setFavicon = (faviconBase64) => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", faviconBase64);
  };

  const getBasicInfo = () => {
    getTemporaryToken(AKV.REACT_APP_BLIP_FOODS_TOKEN).then((res) => {
      getMerchantInfo(merchantId, res.data.temporaryToken).then((res) => {
        setFavicon(res.data.faviconBase64);
        const { data } = res;
        updateUser({
          token: data.token,
          name: data.name,
          image: data.imageUrl,
          faviconBase64: data.faviconBase64,
          merchantId: data.id,
          botKey: data.botKey,
          phone: data.phone,
          contactIdentity: contactIdentity,
          adName: adName,
          minimumPriceValue: data.minimumPriceValue,
          publicKey: data.publicKey,
        });
      });
    });
  };

  return (
    <Box className="children-container">
      {window.location.pathname.includes("payment") ? <></> : <Navbar />}
      <div>{props.children}</div>
    </Box>
  );
};
